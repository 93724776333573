// unique scripts go here.

/*
const sendFormEnter = e => {
  if (window.event.keyCode == '13') {
    sendFormToLeadsStore();
  }
}
*/

const sendFormToLeadsStore = e => {
  //get form data from form.contact-form
  //e.preventDefault();
  const data = getFormData('.contact-form');

  if(data.nome && data.uf && data.cidade && data.email && data.data_de_nascimento && data.telefone && data.autorizacao){
    fetch('https://phpstack-427964-2786102.cloudwaysapps.com/api/form-data', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      console.log(response);
    });
  }
}

const sendFormToApi = e => {
  e.preventDefault();

  const data = getFormData('.contact-form');
  const idGA_lead = document.getElementById("idGA_lead").value;

  if(window.dataLayer){
    window.dataLayer.push({
      'event': 'send',
      'idGA_lead': idGA_lead
    });
  }

  data.state_id = document.querySelector('select[name="uf"] option:checked').dataset.stateid;
  data.url = window.location.href;


  if (validate() == true) {
    showOverlay();
    document.querySelector('.submit-form').disabled = true;

    fetch('includes/put_lead.php', {
        method: "PUT",
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          data
        })
      })
      .then(response => {
        if (response.ok) {
          return response.json();        
        } else {
          throw new Error('Erro ao enviar a proposta.');
        }
      })
      .then(id_planium => {
        // Sweetalert2.fire({
        //   title: 'Sucesso!',
        //   text: 'Proposta enviada com sucesso!',
        //   icon: 'success'
        // })

        cleanInput();
        // fbq('trackCustom', 'lead_amil_cc');
        window.location.href = './confirmacao/?planium='+id_planium;
      })
      .catch(error => {
        Sweetalert2.fire({
          title: 'Ops!',
          text: 'Houve um erro no envio da sua proposta, tente novamente mais tarde.',
          icon: 'error'
        });
        console.error({error});
      })
      .finally(() => {
        hideOverlay();
        document.querySelector('.submit-form').disabled = false;
      });
  } else {
    Sweetalert2.fire({
      title: 'Ops!',
      text: 'Preencha todos os campos',
      icon: 'error'
    })
    hideOverlay();

  }



}


const getStates = e => {
  showOverlay();
  let xhr = new XMLHttpRequest();

  $.ajax({
    type: "GET",
    dataType: "json",
    url: "includes/estados.json",
    success: function (response) {

      //if (response.message === 'Success') {

      const stateSelect = document.querySelector('select[name="uf"]');
      let html = '<option disabled selected value="">Selecione seu Estado</option>';

      // string => array
      // se usar CSV
      /* const CSVToArray = (data, delimiter = ',', omitFirstRow = true) =>
          data
          .slice(omitFirstRow ? data.indexOf('\n') + 1 : 0)
          .split('\n')
          .map(v => v.split(delimiter)); */


      //let estadoSplit = CSVToArray(response);

      response.forEach(state => {
        html += `<option value="${state.uf}">${state.estado}</option>`;
      });

      stateSelect.innerHTML = html;

      /*   } else {
           Sweetalert2.fire({
               title: 'Ops!',
               text: 'Houve um erro com nossa APi, tente novamente mais tarde.',
               icon: 'error'
           })
           console.log('não foi dessa vez');
       }  */

      hideOverlay();
    }
  });
}

const getCities = e => {

  showOverlay();

  $.ajax({
    type: "GET",
    dataType: "json",
    url: "includes/cidades.json",
    data: {
      uf: e.target.value
    },
    success: function (response) {

      //if (response.message === 'Success') {

      const citySelectWrapper = document.querySelector('label.hidden');
      const citySelect = document.querySelector('select[name="cidade"]');
      const regiaoInput = document.querySelector('input[name="regiao_id"]');
      const cidadeInput = document.querySelector('input[name="cidade_id"]');
      let html = '<option value="" data-cidade-id="" data-regiao-id="">Selecione sua Cidade</option>';

      regiaoInput.value = '';
      cidadeInput.value = '';


      let ufForm = e.target.value;

      response.forEach(city => {
        if (ufForm === `${city.uf}`) {
          html += `<option 
            value="${city.município}" 
            data-cidade-id="${city.cidade_id}" 
            data-regiao-id="${city.regiao_id}">
              ${city.município}
          </option>`;
        }
      });

      citySelect.innerHTML = html;

      fadeIn(citySelectWrapper);

      citySelect.addEventListener('change', function () {
        const selectedOption = citySelect.options[citySelect.selectedIndex];
        const regiaoId = selectedOption.getAttribute('data-regiao-id');
        const cidadeId = selectedOption.getAttribute('data-cidade-id');
        // Atualizar os valores dos inputs
        if (regiaoInput) regiaoInput.value = regiaoId;
        if (cidadeInput) cidadeInput.value = cidadeId;
      });
      //}

      /* else {
                Sweetalert2.fire({
                    title: 'Ops!',
                    text: 'Houve um erro com nossa APi, tente novamente mais tarde.',
                    icon: 'error'
                })
            }
 */
      hideOverlay();
    }
  });
}


const maskInstancing = () => {
  var SPMaskBehavior = function (val) {
      return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    spOptions = {
      onKeyPress: function (val, e, field, options) {
        field.mask(SPMaskBehavior.apply({}, arguments), options);
      }
    };

  $('input[name="telefone"]').mask(SPMaskBehavior, spOptions);
  $('input[name="CPF"]').mask('000.000.000-00', {
    reverse: false
  });
  $('input[name="data_de_nascimento"]').mask('00/00/0000');

}

const changeCPFCNPJInput = e => {

  console.log(e.target)

  if (e.target.checked) {
    $("#cpf-cnpj").attr("placeholder", "CNPJ");
    $("#cpf-cnpj").attr("name", "CNPJ");

    $("#cpf-cnpj").mask("00.000.000/0000-00", {
      reverse: false,
    });
  } else {
    $("#cpf-cnpj").attr("placeholder", "CPF");
    $("#cpf-cnpj").attr("name", "CPF");
    $("#cpf-cnpj").mask("000.000.000-00", {
      reverse: false,
      clearIfNotMatch: true,
    });
  }

  $("#cpf-cnpj").val('');

}

const getUTMzz = () => {
  if (getCookie("__utmzz")) {
    var utms = getCookie("__utmzz");
    var utmsA = utms.split("|");
    var utmsFinal = [];
    utmsA.forEach(function (el) {
      var splitedEl = el.split("=");
      utmsFinal[splitedEl[0]] = splitedEl[1];
    });

    document.querySelector('input[name="campaign"]').value = utmsFinal["utmccn"];
    document.querySelector('input[name="medium"]').value = utmsFinal["utmcmd"];
    document.querySelector('input[name="origin"]').value = utmsFinal["utmcsr"];
  }
}

const create_UUID = () => {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}
const slickInstancing = () => {
  $('.slick-wrapper').slick({
    dots: true,
    arrows: false,
  });

  $('.slick-wrapper-campagin').slick({
    dots: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 100
  });

}

const menuMobile = () => {
  let hambuguer = document.querySelector('.menu-hamburguer');
  let menuWrapper = document.querySelector('.menu-items');
  let menuLink = document.querySelectorAll('.menu-items a');


  hambuguer.addEventListener('click', function () {

    if (menuWrapper.classList.contains('hide')) {
      menuWrapper.classList.remove('hide');
    }

    menuWrapper.classList.toggle('menu-mobile');


    menuLink.forEach(link => {
      link.addEventListener('click', function () {
        if (menuWrapper) {
          menuWrapper.classList.add('hide');
          menuWrapper.classList.remove('menu-mobile');
        }
      })
    })
  });
}





const cpfCnpj = document.getElementById('cpf-cnpj');

const validaField = (e) => {
  //e.preventDefault();

  let nome = document.getElementById('nome');
  let telefone = document.getElementById('telefone');
  let email = document.getElementById('email');
  let data_de_nascimento = document.getElementById('data_de_nascimento');
  let uf = document.getElementById('uf');
  let cidade = document.getElementById('cidade');
  //let cnpj = document.getElementById('CNPJ');
  let autorizacao = document.getElementById('autorizacao');

  nome.addEventListener('focusout', function () {
    validateName(this);
  });

  telefone.addEventListener('focusout', function () {
    validatePhone(this);
  });
  email.addEventListener('focusout', function () {
    validateEmail(this);
  });

  data_de_nascimento.addEventListener('focusout', function () {
    validateDob(this);
  });

  uf.addEventListener('focusout', function () {
    validateState(this);
  });

  cidade.addEventListener('focusout', function () {
    validateState(this);
  });

  cpfCnpj.addEventListener('focusout', function () {
    validateCNPJ(this);
  })

  if (autorizacao.checked) {
    validateCheckbox(autorizacao);
  }
}

validaField();

const validateName = (fld) => {
  var valid = true;
  var fldval = fld.value.trim();

  //console.log('------------');
  //console.log('Validating Name');
  //console.log(fldval);

  fld.parentNode.classList.remove('error', 'success');
  if (fldval.length == "") {
    valid = false;
  }

  if (valid) {
    fld.parentNode.classList.add('success');
  } else {
    fld.parentNode.classList.add('error');
  }
  //console.log(valid);
  //console.log('------------');
  return valid;
}

const validateDob = (fld) => {
  var valid = true;
  var fldval = fld.value.trim();
  //console.log('------------');
  //console.log('Validating Name');
  //console.log(fldval);

  fld.parentNode.classList.remove('error', 'success');
  if (fldval.length == "") {
    valid = false;
  }

  if (valid) {
    fld.parentNode.classList.add('success');
  } else {
    fld.parentNode.classList.add('error');
  }
  //console.log(valid);
  //console.log('------------');
  return valid;
}

const validatePhone = (fld, req) => {
  var valid = true;
  var fldval = fld.value.trim();
  //console.log('------------');
  //console.log('Validating Phone Number');
  //console.log(fldval);

  fld.parentNode.classList.remove('error', 'success');
  if (req) {
    if (fldval.length < 14) {
      valid = false;
    }
  } else {
    if (fldval.length > 0) {
      req = true;
      if (fldval.length < 14) {
        valid = false;
      }
    }
  }

  if (req) {
    if (valid) {
      fld.parentNode.classList.add('success');
    } else {
      fld.parentNode.classList.add('error');
    }
  }
  //console.log(valid);
  //console.log('------------');
  return valid;
}

const validateEmail = (fld, confirmation) => {
  var valid = true;
  var fldval = fld.value.trim();
  //console.log('------------');
  //console.log('Validating Name');
  //console.log(fldval);

  fld.parentNode.classList.remove('error', 'success');
  if (fldval.length == 0) {
    valid = false;
  }
  if (!isValidEmail(fldval)) {
    valid = false;
  }

  if (confirmation) {
    if (fldval != document.getElementById('email').value.trim()) {
      valid = false;
    }
  } else {
    document.getElementById('email').blur();
  }

  if (valid) {
    fld.parentNode.classList.add('success');
  } else {
    fld.parentNode.classList.add('error');
  }
  //console.log(valid);
  //console.log('------------');
  return valid;
}

const validateCNPJ = (fld) => {
  var valid = true;
  var fldval = fld.value.trim();
  var tem_CNPJ = document.getElementById('tipo-documento');
  //console.log('------------');
  //console.log('Validating CNPJ');
  //console.log(fldval);

  fld.parentElement.classList.remove('error', 'success');
  if (fldval.length == 0) {
    valid = false;
  }
  if (tem_CNPJ.checked) {
    if (!validarCNPJ(fldval)) {
      valid = false;
    }
  } else {
    if (!validarCPF(fldval)) {
      valid = false;
    }
  }

  if (valid) {
    fld.parentElement.classList.add('success');
  } else {
    fld.parentElement.classList.add('error');
  }

  //console.log(valid);
  //console.log('------------');
  return valid;
}

const validateState = (fld) => {
  var valid = true;
  var fldval = fld.value.trim();
  //console.log('------------');
  //console.log('Validating Name');
  //console.log(fldval);
  fld.parentElement.classList.remove('error', 'success');
  if (fldval.length == 0) {
    valid = false;
  }

  if (valid) {
    fld.parentElement.classList.add('success');
  } else {
    fld.parentElement.classList.add('error');
  }
  //console.log(valid);
  //console.log('------------');
  return valid;
}


const validarCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/\D/g, '');

  if (cnpj.length != 14) {
    return false;
  };

  if (/^(\d)\1{13}$/.test(cnpj)) {
    return false;
  };

  function validaDigitoCnpj(tamanho) {
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(cnpj.length - 2);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      };
    };
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(tamanho - 12)) {
      return false;
    };
    return true;
  };

  if (!validaDigitoCnpj(cnpj.length - 2) || !validaDigitoCnpj(cnpj.length - 1)) {
    return false;
  };
  return true;
};

const validarCPF = (cpf) => {
  cpf = cpf.replace(/\D/g, '');
  
  if (cpf.length != 11) {
    return false;
  };

  if (/^(\d)\1{10}$/.test(cpf)) {
    return false;
  };

  function validaDigitoCpf(pos) {
    let soma = 0;
    for (let i = 1; i <= pos; i++) {
      soma = soma + parseInt(cpf.substring(i - 1, i)) * ((pos + 2) - i);
    };
    let resto = (soma * 10) % 11;
    if ((resto == 10) || (resto == 11)) {
      resto = 0;
    };
    if (resto != parseInt(cpf.substring(pos, (pos + 1)))) {
      return false;
    };
    return true;
  };

  if (!validaDigitoCpf(9) || !validaDigitoCpf(10)) {
    return false;
  };
  return true;
};

const validateCheckbox = (fld) => {
  var valid = true;
  var fldval = fld.value.trim();
  //console.log('------------');
  //console.log('Validating Checkbox01');
  //console.log(fldval);

  fld.parentNode.classList.remove('error', 'success');
  if (fld.checked) {
    valid = true;
  } else {
    valid = false;
  }

  if (valid) {
    fld.parentNode.classList.add('success');
  } else {
    fld.parentNode.classList.add('error');
  }
  //console.log(valid);
  //console.log('------------');
  return valid;
}


function isValidEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}



const validate = (e) => {
  //e.preventDefault();
  var valid = true;
  if (!validateName(nome)) {
    valid = false;
  }
  if (!validatePhone(telefone, true)) {
    valid = false;
  }
  if (!validateState(uf)) {
    valid = false;
  }
  if (!validateEmail(email)) {
    valid = false;
  }
  if (!validateDob(data_de_nascimento)) {
    valid = false;
  }

  if (!validateCNPJ(cpfCnpj)) {
    valid = false;
  }

  if (!validateCheckbox(autorizacao)) {
    valid = false;
  }

  //console.log('valor do valid: ' + valid);
  return valid;
};

//validate();


/* const sendDate = () => {
    let form = document.querySelector('.contact-form');
    form.addEventListener('submit', function (e) {
        e.preventDefault();
        if (validate() == true) {
            Sweetalert2.fire({
                title: 'Sucesso!',
                text: 'Proposta enviada com sucesso!',
                icon: 'success'
            });


        } else {
            Sweetalert2.fire({
                title: 'Ops!',
                text: 'Houve um erro no envio da sua proposta, tente novamente mais tarde.',
                icon: 'error'
            })
        }
    });
} */

//sendDate();


const cleanInput = () => {
  let nome = document.getElementById('nome');
  let telefone = document.getElementById('telefone');
  let email = document.getElementById('email');
  let data_de_nascimento = document.getElementById('data_de_nascimento');
  let uf = document.getElementById('uf');
  let cidade = document.getElementById('cidade');
  let autorizacao = document.getElementById('autorizacao');
  let tipoDoc = document.getElementById('tipo-documento');
  const citySelectWrapper = document.querySelector('label.hidden');


  nome.value = '';
  nome.parentElement.classList.remove('error', 'success');

  telefone.value = '';
  telefone.parentElement.classList.remove('error', 'success');

  email.value = '';
  email.parentElement.classList.remove('error', 'success');

  data_de_nascimento.value = '';
  data_de_nascimento.parentElement.classList.remove('error', 'success');

  uf.value = '';
  uf.parentElement.classList.remove('error', 'success');

  cidade.value = '';
  fadeOut(citySelectWrapper);

  cpfCnpj.value = '';
  cpfCnpj.parentElement.classList.remove('error', 'success');

  tipoDoc.checked = false;
  autorizacao.checked = false;

}




function createAnimations() {
  const controller = new ScrollMagic.Controller();      
  const tweenCards = TweenMax.staggerFrom(".box", 1,
      {opacity: 0},
      2        
  ); 	

  const cards = new ScrollMagic.Scene({triggerElement: "#boxes", duration:400, offset: -120})
  .setTween(tweenCards)    
  .addTo(controller);   
}

//createAnimations();
